import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './DropdownMenu.css';
import MenuIcon from './Smenu6.svg';

function DropdownMenu() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img src={MenuIcon} className="dropdown-logo" alt="Menu"/>
      {isDropdownOpen && (
        <div className="dropdown-content" onClick={handleDropdownClick}>
          <Link to="/" className="dropdown-link">HOME</Link>
          <Link to="/about" className="dropdown-link">ABOUT</Link>
          <Link to="/procedures" className="dropdown-link">PROCEDURES</Link>
          <Link to="/surgeries" className="dropdown-link">SURGERIES</Link>
          <Link to="/language" className="dropdown-link">EN</Link>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
