import React, { useState } from 'react';
import './InteractiveImageComponent.css';
import { useNavigate } from 'react-router-dom';
// Import images
import procedures from './procedures7.jpg';
import surgeries from './surgery7.jpg';
// Add more images if needed

function InteractiveImageComponent() {
  const [currentImage, setCurrentImage] = useState(procedures);
  const [hoveredSection, setHoveredSection] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (section) => {
    setCurrentImage(section.image);
    setHoveredSection(section.name);
  };

  const sections = [
    { name: 'PROCEDURES', image: procedures },
    { name: 'SURGERIES', image: surgeries },
    // Add more sections as necessary
  ];

  const handleSectionClick = (section) => {
    window.scrollTo(0, 0);
    if (section.name === 'PROCEDURES') {
      navigate('/procedures');
    } else if (section.name === 'SURGERIES') {
      navigate('/surgeries');
    }
  };

  return (
    <div className="interactive-image-container" style={{ backgroundImage: `url(${currentImage})` }}>
      <div className="interactive-sections">
        {sections.map((section) => (
          <div
            key={section.name}
            onMouseEnter={() => handleMouseEnter(section)}
            onMouseLeave={() => setHoveredSection(null)}
            onClick={() => handleSectionClick(section)}
            style={{ opacity: hoveredSection === section.name ? 1 : 0 }}
          >
            {section.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InteractiveImageComponent;
