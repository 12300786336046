export const surgeries = [
    { 
      id: 1, 
      name: 'Face Lifting', 
      content: `
      As we age, facial changes due to skin laxity, fat atrophy, muscle action, and bone resorption lead to a tired appearance.

      Facelift surgery (rhytidectomy) addresses these by repositioning internal structures and skin, restoring a youthful look. We often perform fat grafting, rich in stem cells, to enhance facial depressions and skin quality.
      
      A facelift can be combined with blepharoplasty, mentoplasty, and CO2 fractional laser. General anesthesia or sedation ensures tolerance to high-power laser treatments.
      
      Surgery: Typically performed under local anesthesia with sedation. Patients usually stay overnight.
      
      Post-Operative Care:
      
      First Week: Stitches removed from day 7.
      After 20 Days: Resume daily activities.
      After 30 Days: Lower body physical activities permitted.
      After 60 Days: Upper body physical activities allowed.
      Our goal is a smooth recovery and comprehensive care for the best results.
      `, 
      image: './surgeriesfolder/Face_Lifting.jpg' 
    },
    { id: 2, name: 'Rhinoplasty', content: 'Content for Rhinoplasty', image: './surgeriesfolder/Rhinoplasty.jpg' },
    { id: 3, name: 'Blepharoplasty', content: 'Content for Blepharoplasty', image: './surgeriesfolder/Blepharoplasty.jpg' },
    { id: 4, name: 'Otoplasty', content: 'Content for Otoplasty', image: './surgeriesfolder/Otoplasty.jpg' },
    { id: 5, name: 'Mentoplasty', content: 'Content for Mentoplasty', image: '../surgeriesfolder/Mentoplasty.jpg' },
    { id: 6, name: 'Breast Augmentation', content: 'Content for Breast Prosthesis', image: './surgeriesfolder/Breast_Prosthesis.jpg' },
    { id: 7, name: 'Breast Reduction', content: 'Content for Reduction Mammoplasty', image: './surgeriesfolder/Breast_Reduction.jpg' },
    { id: 8, name: 'Breast Lift', content: 'Content for Lift Mammoplasty', image: './surgeriesfolder/Breast_Lift.jpg' },
    { id: 9, name: 'Gynecomastia', content: 'Content for Gynecomastia', image: './surgeriesfolder/Gynecomastia.jpg' },
    { id: 10, name: 'Mommy Makeover', content: 'Content for Mommy Makeover', image: './surgeriesfolder/Mommy_Makeover.jpg' },
    { id: 11, name: 'Abdominoplasty', content: 'Content for Abdominoplasty', image: './surgeriesfolder/Abdominoplasty.jpg' },
    { id: 12, name: 'Liposuction', content: 'Content for Liposuction', image: './surgeriesfolder/Liposuction.jpg' },
    { id: 13, name: 'Brachioplasty and Thigh Lifting', content: 'Content for Brachioplasty and Thigh Lifting', image: './surgeriesfolder/Brachioplasty_and_Thigh_Lifting.jpg' },
    { id: 14, name: 'Intimate Surgery', content: 'Content for Intimate Surgery', image: './surgeriesfolder/Intimate_Surgery.jpg' },

    
    { id: 19, name: 'Bichectomy', content: 'Content for Bichectomy', image: './surgeriesfolder/Bichectomy.jpg' },
    { id: 20, name: 'Scar Correction', content: 'Content for Scar Correction', image: './surgeriesfolder/Scar_Correction.jpg' },
    { id: 21, name: 'Reconstructive Plastic Surgery', content: 'Content for Reconstructive Plastic Surgery', image: './surgeriesfolder/Reconstructive_Plastic_Surgery.jpg' },
    
    
    { id: 16, name: 'Tumores Cutaneos', content: 'Content for Tumores Cutâneos', image: './surgeriesfolder/Tumores_Cutaneos.jpg' },
    

  ];
  