// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import SurgeonProfile from './SurgeonProfile';
import InteractiveImageComponent from './InteractiveImageComponent';
import ContactImage from './ContactImage.png';
import ButtonImage from './ButtonImage.svg';

const images = [
    'homepageimg1.png',
    'riofinal.png',
    'colfinal.png',
];

function HomePage() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <section className="App-slideshow">
                <div className="image-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index}`}
                            className={`fade-image ${index === currentImageIndex ? 'visible' : ''}`}
                        />
                    ))}
                    <button className="book-appointment-btn">Book Appointment</button>
                </div>
            </section>

            <SurgeonProfile />

            <InteractiveImageComponent />

            <div className="contact-image-container">
                <img src={ContactImage} alt="Contact" className="contact-image" />
                <button className="whatsapp-btn">
                    <img src={ButtonImage} alt="WhatsApp Icon" className="button-image" /> WhatsApp
                </button>
            </div>
        </div>
    );
}

export default HomePage;
