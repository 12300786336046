export const procedures = [
  { 
    id: 1, 
    name: 'Botox', 
    content: 'Content for Botox', 
    image: './proceduresfolder/Botox.jpg' 
  },
  { id: 2, name: 'Botulinum Toxin', content: 'Content for Botulinum Toxin', image: './proceduresfolder/2op.jpg' },
  { id: 3, name: 'Collagen Biostimulators (Sculptra / Radiesse)', content: 'Content for Collagen Biostimulators (Sculptra / Radiesse)', image: './proceduresfolder/3.jpg' },
  { id: 4, name: 'Hand Rejuvenation (Radiesse)', content: 'Content for Hand Rejuvenation (Radiesse)', image: './proceduresfolder/4.jpg' },
  { id: 5, name: 'Ultraformer', content: 'Content for Ultraformer', image: './proceduresfolder/5.jpg' },
  { id: 6, name: 'Sculptra Abdomen', content: 'Content for Sculptra Abdomen', image: './proceduresfolder/6.jpg' },
  { id: 7, name: 'Hyaluronic Acid (Cheek)', content: 'Content for Hyaluronic Acid (Cheek)', image: '../proceduresfolder/7.jpg' },
  { id: 8, name: 'Hyaluronic Acid (Jaw)', content: 'Content for Hyaluronic Acid (Jaw)', image: './proceduresfolder/8.jpg' },
  { id: 9, name: 'Hyaluronic Acid (Dark Circles)', content: 'Content for Hyaluronic Acid (Dark Circles)', image: './proceduresfolder/9.jpg' },
  { id: 10, name: 'Botox Hyperhidrosis (Axillary)', content: 'Content for Botox Hyperhidrosis (Axillary)', image: './proceduresfolder/10.jpg' },
  { id: 11, name: 'Cervical Botox', content: 'Content for Cervical Botox', image: './proceduresfolder/11.jpg' },
  { id: 12, name: 'Therapeutic Botulinum Toxin (Migraine/Bruxism)', content: 'Content for Therapeutic Botulinum Toxin (Migraine/Bruxism)', image: './proceduresfolder/12.jpg' },
  { id: 13, name: 'Laser', content: 'Content for Laser', image: './proceduresfolder/13.jpg' },
  { id: 14, name: 'Lipoenzyme Deoxycholic Acid (Cervical/Body)', content: 'Content for Lipoenzyme Deoxycholic Acid (Cervical/Body)', image: './proceduresfolder/14.jpg' },
  { id: 15, name: 'Mentoplasty', content: 'Content for Mentoplasty', image: './proceduresfolder/15.jpg' },
  { id: 16, name: 'Microneedling', content: 'Content for Microneedling', image: './proceduresfolder/16.jpg' },
  { id: 17, name: 'Chemical Peel', content: 'Content for Chemical Peel', image: './proceduresfolder/17.jpg' },
  { id: 18, name: 'Lip Fill', content: 'Content for Lip Fill', image: './proceduresfolder/18.jpg' },
  { id: 19, name: 'MD Codes or Facial Harmonization', content: 'Content for MD Codes or Facial Harmonization', image: './proceduresfolder/19.jpg' },
  { id: 20, name: 'Skinbooster (Periocular/Perioral)', content: 'Content for Skinbooster (Periocular/Perioral)', image: './proceduresfolder/20.jpg' },
  { id: 21, name: 'Wound Suturing', content: 'Content for Wound Suturing', image: './proceduresfolder/21.jpg' },
  // Add more surgeries as needed
];
