import React from 'react';
import './Footer.css';
import { ReactComponent as Logo } from './logofooter.svg'; // Import your logo as a React component
// import { ReactComponent as FacebookIcon } from './facebook-icon.svg'; // Import your icons as React components
import { ReactComponent as InstagramIcon } from './instagram-icon.svg';
import { ReactComponent as WhatsappIcon } from './whatsapp-icon.svg';
// import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Footer() {
    return (
        <footer className="App-footer">
            <div className="footer-row footer-top">
                {/* <Link to="/"> */}
                    <Logo className="footer-logo" />
                {/* </Link> */}
                <div className="footer-social-contact">
                    <div className="social-icons">
                        {/* <FacebookIcon /> */}
                        <InstagramIcon />
                        <WhatsappIcon />
                    </div>
                </div>
                <div className="footer-row footer-address">
                    <span>Almirante Guilhen 366 Leblon 4 andar</span>
                </div>
                <div className="footer-contact">
                    <a href="mailto:contacto@juansalazar.com">contacto@juansalazar.com</a>
                </div>
            </div>
            <div className="footer-row footer-privacy">
                <a href="/privacy-policy">Privacy Policy</a>
            </div>
            <div className="footer-row footer-bottom">
                <span>Clinica Dr. Juan Salazar © Powered by Felipe Castro </span>
            </div>
        </footer>
    );
}

export default Footer;
