import React, { useState } from 'react';
import './ComingSoon.css'; // Import the CSS for styling
// import Logo from './LOGOjuandavid.svg';
import ImageClinic from './imclinic2.svg';
const ComingSoon = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you could add what you want to do with the email.
    // For example, sending it to a server or logging it.
    console.log('Email submitted:', email);
    // Reset email field after submission
    setEmail('');
  };

  return (
    <div className="coming-soon-container">
        {/* <img src={Logo} className="App-logo-ComingSoon" alt="logo-ComingSoon" />    */}
        <img src={ImageClinic} className="Image-Clinic" alt="clinic" /> 
        <div className="coming-soon-container-1">
            {/* <h1>Coming Soon</h1>
            <p>We're working hard to finish the development of this site. Sign up below to receive updates and to be notified when we launch!</p> */}
            <form onSubmit={handleSubmit} className="email-signup-form">
                <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
                <button type="submit">Sign Up</button>
            </form>
        </div>
    </div>
  );
};

export default ComingSoon;
